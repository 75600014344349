
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutcGvMViY0SnMeta } from "/opt/buildhome/repo/pages/about.vue?macro=true";
import { default as _91blogSlug_93Yj4virLYICMeta } from "/opt/buildhome/repo/pages/blog/[blogSlug].vue?macro=true";
import { default as _91blogCategory_935Vh4r1KYtZMeta } from "/opt/buildhome/repo/pages/blog/category/[blogCategory].vue?macro=true";
import { default as indexnCitm8tluDMeta } from "/opt/buildhome/repo/pages/blog/index.vue?macro=true";
import { default as consultancypsHirthA55Meta } from "/opt/buildhome/repo/pages/consultancy.vue?macro=true";
import { default as contactupLWW5VsuQMeta } from "/opt/buildhome/repo/pages/contact.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as privacy_45policypnUDKF1OY5Meta } from "/opt/buildhome/repo/pages/privacy-policy.vue?macro=true";
import { default as searchW2JMgpGXtYMeta } from "/opt/buildhome/repo/pages/search.vue?macro=true";
import { default as terms_45of_45useAAGTgp8alZMeta } from "/opt/buildhome/repo/pages/terms-of-use.vue?macro=true";
import { default as testimonialgDaTmT2JzPMeta } from "/opt/buildhome/repo/pages/testimonial.vue?macro=true";
import { default as _91slug_93GaNLURlgyfMeta } from "/opt/buildhome/repo/pages/us-immigration/[category]/[slug].vue?macro=true";
import { default as _91slug_93e6068kXoS4Meta } from "/opt/buildhome/repo/pages/us-immigration/[slug].vue?macro=true";
import { default as video3WpShBW0HFMeta } from "/opt/buildhome/repo/pages/video.vue?macro=true";
import { default as webinarzdFV6GNDB3Meta } from "/opt/buildhome/repo/pages/webinar.vue?macro=true";
export default [
  {
    name: "about___en",
    path: "/about",
    component: () => import("/opt/buildhome/repo/pages/about.vue")
  },
  {
    name: "about___tr",
    path: "/tr/hakkimizda",
    component: () => import("/opt/buildhome/repo/pages/about.vue")
  },
  {
    name: "blog-blogSlug___en",
    path: "/blog/:blogSlug()",
    component: () => import("/opt/buildhome/repo/pages/blog/[blogSlug].vue")
  },
  {
    name: "blog-blogSlug___tr",
    path: "/tr/blog/:blogSlug()",
    component: () => import("/opt/buildhome/repo/pages/blog/[blogSlug].vue")
  },
  {
    name: "blog-category-blogCategory___en",
    path: "/blog/category/:blogCategory()",
    component: () => import("/opt/buildhome/repo/pages/blog/category/[blogCategory].vue")
  },
  {
    name: "blog-category-blogCategory___tr",
    path: "/tr/blog/kategori/:blogCategory()",
    component: () => import("/opt/buildhome/repo/pages/blog/category/[blogCategory].vue")
  },
  {
    name: "blog___en",
    path: "/blog",
    component: () => import("/opt/buildhome/repo/pages/blog/index.vue")
  },
  {
    name: "blog___tr",
    path: "/tr/blog",
    component: () => import("/opt/buildhome/repo/pages/blog/index.vue")
  },
  {
    name: "consultancy___en",
    path: "/consultancy",
    component: () => import("/opt/buildhome/repo/pages/consultancy.vue")
  },
  {
    name: "consultancy___tr",
    path: "/tr/danismanlik",
    component: () => import("/opt/buildhome/repo/pages/consultancy.vue")
  },
  {
    name: "contact___en",
    path: "/contact",
    component: () => import("/opt/buildhome/repo/pages/contact.vue")
  },
  {
    name: "contact___tr",
    path: "/tr/bize-ulasin",
    component: () => import("/opt/buildhome/repo/pages/contact.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "index___tr",
    path: "/tr",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/opt/buildhome/repo/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___tr",
    path: "/tr/gizlilik-politikasi",
    component: () => import("/opt/buildhome/repo/pages/privacy-policy.vue")
  },
  {
    name: "search___en",
    path: "/search",
    component: () => import("/opt/buildhome/repo/pages/search.vue")
  },
  {
    name: "search___tr",
    path: "/tr/ara",
    component: () => import("/opt/buildhome/repo/pages/search.vue")
  },
  {
    name: "terms-of-use___en",
    path: "/terms-of-use",
    component: () => import("/opt/buildhome/repo/pages/terms-of-use.vue")
  },
  {
    name: "terms-of-use___tr",
    path: "/tr/kullanim-kosullari",
    component: () => import("/opt/buildhome/repo/pages/terms-of-use.vue")
  },
  {
    name: "testimonial___en",
    path: "/testimonial",
    component: () => import("/opt/buildhome/repo/pages/testimonial.vue")
  },
  {
    name: "testimonial___tr",
    path: "/tr/muvekkillerimizden",
    component: () => import("/opt/buildhome/repo/pages/testimonial.vue")
  },
  {
    name: "us-immigration-category-slug___en",
    path: "/us-immigration/:category()/:slug()",
    component: () => import("/opt/buildhome/repo/pages/us-immigration/[category]/[slug].vue")
  },
  {
    name: "us-immigration-category-slug___tr",
    path: "/tr/amerika-gocmenlik/:category()/:slug()",
    component: () => import("/opt/buildhome/repo/pages/us-immigration/[category]/[slug].vue")
  },
  {
    name: "us-immigration-slug___en",
    path: "/us-immigration/:slug()",
    component: () => import("/opt/buildhome/repo/pages/us-immigration/[slug].vue")
  },
  {
    name: "us-immigration-slug___tr",
    path: "/tr/amerika-gocmenlik/:slug()",
    component: () => import("/opt/buildhome/repo/pages/us-immigration/[slug].vue")
  },
  {
    name: "video___en",
    path: "/video",
    component: () => import("/opt/buildhome/repo/pages/video.vue")
  },
  {
    name: "video___tr",
    path: "/tr/video",
    component: () => import("/opt/buildhome/repo/pages/video.vue")
  },
  {
    name: "webinar___en",
    path: "/webinar",
    component: () => import("/opt/buildhome/repo/pages/webinar.vue")
  },
  {
    name: "webinar___tr",
    path: "/tr/webinar",
    component: () => import("/opt/buildhome/repo/pages/webinar.vue")
  }
]