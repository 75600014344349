import revive_payload_client_6ubKohsb9G from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_g7bnwlroic2kdhi5ik2rgh7yru/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_2dNYHUwhuZ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_g7bnwlroic2kdhi5ik2rgh7yru/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_79KxHpjZQN from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_g7bnwlroic2kdhi5ik2rgh7yru/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_rFvQ1PmNmB from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.34.2_vite@6.0.11_@types+node@22.13.1_jiti@2.4._ixlp4d4xtj5sp43gyhu7idx7jq/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_pvOob2YXAy from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_g7bnwlroic2kdhi5ik2rgh7yru/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Fk6St6KsOY from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_g7bnwlroic2kdhi5ik2rgh7yru/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_v1jFn0InNN from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_g7bnwlroic2kdhi5ik2rgh7yru/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_jorpCRg9rz from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_g7bnwlroic2kdhi5ik2rgh7yru/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_zlvi6dcIsi from "/opt/buildhome/repo/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_TJ3FERUkDO from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_g7bnwlroic2kdhi5ik2rgh7yru/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_eFDfI35gQg from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.34.2_vite@6.0.11_@types+node@22.13.1_jiti@2.4.2__gsi7s4me2yguy32fbxwk42ug3y/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_uuByUY7ZAX from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.34.2_vite@6.0.11_@types+node@22.13.1_jiti@2.4.2__gsi7s4me2yguy32fbxwk42ug3y/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_fatT9ktVg9 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.34.2_vite@6.0.11_@types+node@22.13.1_jiti@2.4.2__gsi7s4me2yguy32fbxwk42ug3y/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_faeG0rZAxP from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.34.2_vite@6.0.11_@types+node@22.13.1_jiti@2.4.2__gsi7s4me2yguy32fbxwk42ug3y/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import motion_9eiFFcTQRA from "/opt/buildhome/repo/node_modules/.pnpm/@vueuse+motion@2.2.6_magicast@0.3.5_rollup@4.34.2_vue@3.5.13_typescript@5.7.3_/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import switch_locale_path_ssr_VIORUSkl6v from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_ywiugpo6mips4es55pmluc56g4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_E6ks3F7b1b from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_ywiugpo6mips4es55pmluc56g4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_CoTdIKYFeY from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_ywiugpo6mips4es55pmluc56g4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import slideovers_E84fq76l2c from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.34.2_typescript@5._vvp47x7xtivbckwfe46ta3mxdq/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_fgz98cZYUG from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.34.2_typescript@5._vvp47x7xtivbckwfe46ta3mxdq/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_fgJsi78nUl from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.34.2_typescript@5._vvp47x7xtivbckwfe46ta3mxdq/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_fUdugSo8s1 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.34.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_OpC4E79rV5 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.34.2_vite@6.0.11_@types+node@22.13.1_jiti@2.4.2_ter_ltxyetu4obp5s2nmc4pzxpoo2q/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_HZyM9Yobj5 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.34.2_typescript_a3hdakut3twvvfuy3hrrrhmlq4/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_DXwvLunrQy from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.34.2_typescript_a3hdakut3twvvfuy3hrrrhmlq4/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_gL3mvSrqWe from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.34.2_typescript_a3hdakut3twvvfuy3hrrrhmlq4/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import autoAnimate_4HQGapz9xs from "/opt/buildhome/repo/plugins/autoAnimate.ts";
import externalLink_xmXClNpE4R from "/opt/buildhome/repo/plugins/externalLink.ts";
import feedbucket_client_bcif5LEtbp from "/opt/buildhome/repo/plugins/feedbucket.client.ts";
import motion_client_wgdmc4x1or from "/opt/buildhome/repo/plugins/motion.client.ts";
import sentry_client_shVUlIjFLk from "/opt/buildhome/repo/plugins/sentry.client.ts";
import ssg_detect_ax7mw71BNn from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_ywiugpo6mips4es55pmluc56g4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_6ubKohsb9G,
  unhead_2dNYHUwhuZ,
  router_79KxHpjZQN,
  _0_siteConfig_rFvQ1PmNmB,
  payload_client_pvOob2YXAy,
  navigation_repaint_client_Fk6St6KsOY,
  check_outdated_build_client_v1jFn0InNN,
  chunk_reload_client_jorpCRg9rz,
  components_plugin_zlvi6dcIsi,
  prefetch_client_TJ3FERUkDO,
  siteConfig_eFDfI35gQg,
  inferSeoMetaPlugin_uuByUY7ZAX,
  titles_fatT9ktVg9,
  defaultsWaitI18n_faeG0rZAxP,
  motion_9eiFFcTQRA,
  switch_locale_path_ssr_VIORUSkl6v,
  route_locale_detect_E6ks3F7b1b,
  i18n_CoTdIKYFeY,
  slideovers_E84fq76l2c,
  modals_fgz98cZYUG,
  colors_fgJsi78nUl,
  plugin_client_fUdugSo8s1,
  plugin_OpC4E79rV5,
  scrollbars_client_HZyM9Yobj5,
  presets_DXwvLunrQy,
  variables_gL3mvSrqWe,
  autoAnimate_4HQGapz9xs,
  externalLink_xmXClNpE4R,
  feedbucket_client_bcif5LEtbp,
  motion_client_wgdmc4x1or,
  sentry_client_shVUlIjFLk,
  ssg_detect_ax7mw71BNn
]