import routerOptions0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_g7bnwlroic2kdhi5ik2rgh7yru/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.34.2_typescript_a3hdakut3twvvfuy3hrrrhmlq4/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}