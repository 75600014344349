import type { DirectiveBinding } from "vue";
import DOMPurify from "dompurify";

export default defineNuxtPlugin({
  name: "external-link",
  parallel: true,
  setup(nuxtApp) {
    nuxtApp.vueApp.directive("external-link", {
      mounted(el: HTMLElement, _: DirectiveBinding) {
        // sanitize the html to prevent XSS
        const originalHTML = el.innerHTML;
        const sanitizedHTML = DOMPurify.sanitize(originalHTML);
        el.innerHTML = sanitizedHTML;

        // http images to https
        const images = el.querySelectorAll<HTMLImageElement>("img");
        images.forEach((img) => {
          if (img.src.startsWith("http://")) {
            img.src = img.src.replace("http://", "https://");
          }
        });

        // links
        const links = el.querySelectorAll<HTMLAnchorElement>("a");

        links.forEach((link) => {
          const href = link.getAttribute("href");
          const currentDomain = window.location.hostname;

          if (href && !href.includes(currentDomain)) {
            link.setAttribute("target", "_blank");
            link.setAttribute("rel", "noopener noreferrer");
          }
        });
      },
    });
  },
});
